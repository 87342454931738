import { LandingPageHeaderProps } from '@uikit/components/LandingPageHeader/types';

type HeaderNodeProps = {
  title: string;
} & (
  | {
      link: 'url';
      url: string;
    }
  | {
      link: 'page';
      page: string;
    }
);

type HeaderNode = {
  props: HeaderNodeProps;
};

type HeaderAreaContent = {
  props: LandingPageHeaderProps;
  contents?: HeaderNode[];
};

// TODO: create transformer for header area in process data and pass as headerProps of page props and after that remove this function
export const transformLandingPageHeaderArea = (headerAreaContents: unknown) => {
  const [header] = (headerAreaContents as HeaderAreaContent[]) ?? [];
  return {
    ...header?.props,
    items: header?.contents?.map(({ props }) => {
      return {
        isInternalUrl: props.link === 'page',
        title: props.title,
        url: props.link === 'page' ? props.page : props.url,
      };
    }),
  };
};
