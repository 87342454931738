import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
import { HEADER_LOGO_HEIGHTS } from '../NewHeader/consts';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EnercityLandingPageHeaderProps } from './types';

const EnercityLandingPageHeader = (props: EnercityLandingPageHeaderProps) => {
  const isMobile = useIsMobile(BreakPoint.LG);
  const height = HEADER_LOGO_HEIGHTS.enercity[isMobile ? 'mobile' : 'desktop'];

  return (
    <LandingPageHeader
      {...props}
      logo={<ProjectLogo project="enercity" height={height} />}
    />
  );
};

export default EnercityLandingPageHeader;
