import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EnercityLandingPageFooterProps } from './types';

const EnercityLandingPageFooter = ({
  skewOverlap,
}: EnercityLandingPageFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity AG"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={54} project="enercity" solidFill />}
      skewOverlap={skewOverlap}
    />
  );
};

export default EnercityLandingPageFooter;
