import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import { transformLandingPageHeaderArea } from '@/helpers/transformLandingPageHeaderArea';
import type { PageModel } from '@/models/page';

import EnercityLandingPageHeader from '@uikit/components/EnercityLandingPageHeader/EnercityLandingPageHeader';

import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import BackToTopButton from '@uikit/components/BackToTopButton/BackToTopButton';
import EnercityLandingPageFooter from '@uikit/components/EnercityLandingPageFooter/EnercityLandingPageFooter';
import Layout from '@uikit/components/Layout/Layout';
import { LayoutVariant } from '@uikit/components/Layout/interfaces';
import Modal from '@uikit/components/Modal/Modal';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './LandingPage.cmsComponents';

const LandingPage = (pageData: PageModel): JSX.Element => {
  const meta = extractPageMetaFromRouteData(pageData);

  const { contents, modals } = pageData;

  const {
    extractedContents: [headerArea, noticeBarArea],
    restContents,
  } = extractContents(['landing-page-header', 'notice-bar-area'], contents);

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}
      <Layout variant={LayoutVariant.Default}>
        <EnercityLandingPageHeader
          {...transformLandingPageHeaderArea(headerArea)}
        />

        <Page meta={meta}>
          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton />

        <EnercityLandingPageFooter />
      </Layout>

      <GenericModals modals={modals} modalComponent={Modal} />
    </CmsComponentsContext.Provider>
  );
};

export default LandingPage;
